<template>
  <div id="status">
    {{ status }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { ILLI_API_SERVER_URL } from "../config";

export default {
  data() {
    return {
      status: "busy"
    };
  },
  mounted() {
    axios
      .post(`${ILLI_API_SERVER_URL}/updatePlatformMQTTPassword`, {})
      .then((response) => {
        if (response.status === 200) {this.status = "Done!"}
      })
      .catch(() => {
        this.status = "Failed!"
      });
  },
  computed: {
    ...mapGetters({
      system: "system/system",
      user: "auth/user",
    }),
  },
  methods: {
    
  },
};
</script>                